<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Multiselect from "vue-multiselect";
//import DatePicker from "vue2-datepicker";
//import CKEditor from "@ckeditor/ckeditor5-vue";
//import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { apiRequest } from "@/helpers/api-call";
//import { format } from "date-fns";
import router from "@/router/index.js";
import Vue from "vue";

/**
 * Orders Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
  },
  data() {
    return {
      title: "liste des chauffeurs",
      items: [
        {
          text: "Dashboard",
        },
        {
          text: "liste",
          active: true,
        },
      ],
      userType: [
        {
          name: "CONDUCTEUR",
        },
        {
          name: "PARTICULER",
        },
        {
          name: "TAXIMAN",
        },
        {
          name: "SOCIETE",
        },
      ],
      sexe: ["M", "F", "O"],
      sexeSelect: "",
      userTypeSelect: "",
      value: null,
      value1: null,
      options: ["Sexe", "Taille", "Vehicule", "je vois"],
      idUser: 0,
      dataUser: [],
      dataUserD: [],
      nbreUser: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [100, 250, 500],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      ordersData: [],
      ordersDataOff: [],
      fields: [
        { key: "nom", sortable: true, label: "Nom" },
        { key: "prenom", sortable: true, label: "Prénom" },
        { key: "sexe", sortable: true, label: "Sexe" },
        { key: "contact", sortable: true, label: "Numéro de téléphone" },
        { key: "adresse", sortable: true, label: "Adresse" },
        { key: "email", sortable: true, label: "Email" },
        { key: "action", sortable: true, label: "Actions" },
      ],

      fileProfil: null,
      filePermis: null,
      fileCni: null,
      nom: "",
      prenom: "",
      adresse: "",
      indicatifContact: "",
      contact: "",
      datenaissance: "",
      email: "",
      numeropiece: "",
      idChauffeur: 0,

      submitform: false,

      showDesactiveModal: false,
      showActiveModal: false,
      showEditModal: false,
      showSucess: false,
      showEchec: false,
      showSucessEdit: false,
      showEchecEdit: false,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.dataUser.length;
    },
  },
  async mounted() {
    this.init();
  },
  methods: {
    async init() {
      //Chargement des données
      const data = await apiRequest(
        "GET",
        "admin/entreprise/chauffeur",
        undefined,
        false
      );
      if (data && data.data && data.data.chauffeurActif) {
        const formattedTable = data.data.chauffeurActif.map((chauffeur) => {
          return {
            id: chauffeur.id,
            nom: chauffeur.nom,
            prenom: chauffeur.prenom,
            sexe: chauffeur.genre,
            contact: chauffeur.indicatifContact + " " + chauffeur.contact,
            adresse: chauffeur.ville,
            email: chauffeur.email,
            datenaissance: chauffeur.datenaissance,
            numeropiece: chauffeur.numeropiece,
          };
        });
        this.ordersData = formattedTable;

        const formattedTableOff = data.data.chauffeurInActif.map(
          (chauffeur) => {
            return {
              id: chauffeur.id,
              nom: chauffeur.nom,
              prenom: chauffeur.prenom,
              sexe: chauffeur.genre,
              contact: chauffeur.indicatifContact + " " + chauffeur.contact,
              adresse: chauffeur.ville,
              email: chauffeur.email,
              datenaissance: chauffeur.datenaissance,
              numeropiece: chauffeur.numeropiece,
            };
          }
        );
        this.ordersDataOff = formattedTableOff;
      }
    },
    async editChauffeurSubmit() {
      const editData = await apiRequest(
        "POST",
        "admin/entreprise/chauffeur-update",
        {
          id: this.idChauffeur,
          nom: this.nom,
          prenom: this.prenom,
          genre: this.sexeSelect,
          datenaissance: this.datenaissance,
          ville: this.adresse,
          indicatifContact: "",
          contact: this.contact,
          email: this.email,
          numeropiece: this.numeropiece,
          profil: "",
          cni: "",
          permis: "",
        },
        false
      );

      if (editData && editData.data) {
        this.showSucessEdit = true;
        this.init();
        this.showEditModal = false;
      } else {
        this.showEchecEdit = true;
        this.init();
        this.showEditModal = false;
      }
    },
    async editChauffeur(row) {
      this.showEditModal = true;
      this.idChauffeur = row.id;

      const dataDetail = await apiRequest(
        "GET",
        "admin/entreprise/detail-chauffeur/?id=" + this.idChauffeur,
        {},
        false
      );

      if (dataDetail && dataDetail.data) {
        let detail = dataDetail.data.chauffeur;
        //console.log("Detail User", dataDetail.data)
        this.nom = detail.nom;
        this.prenom = detail.prenom;
        this.genre = detail.genre;
        this.datenaissance = detail.datenaissance;
        this.ville = detail.ville;
        this.indicatifContact = "";
        this.contact = detail.contact;
        this.email = detail.email;
        this.numeropiece = detail.numeropiece;
      }
    },
    desactiveChauffeur(row) {
      this.idChauffeur = row.id;
      this.showDesactiveModal = true;
    },
    activeClient(row) {
      this.idChauffeur = row.id;
      this.showActiveModal = true;
    },
    async desactiveClientSubmit() {
      this.showDesactiveModal = false;
      const data = await apiRequest(
        "POST",
        "admin/entreprise/action-chauffeur",
        { idChauffeur: this.idChauffeur, active: false },
        false
      );
      //console.log("data.data:", data);
      if (data && data.data) {
        this.position();
        this.init();
      }
    },
    async activeClientSubmit() {
      const data = await apiRequest(
        "POST",
        "admin/entreprise/action-chauffeur",
        { idChauffeur: this.idChauffeur, active: true },
        false
      );
      //console.log("data.data:", data);
      if (data && data.data) {
        this.position();
        this.init();
        this.showActiveModal = false;
      }
    },
    position() {
      Vue.swal({
        position: "center-end",
        icon: "success",
        title: "Mise à jour effectuée",
        showConfirmButton: false,
        timer: 3000,
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    goToDetail(row) {
      console.log("row:", row);
      router.push(`/chauffeur/details/${row.id}`);
      //router.push(`/chauffeur/details/${row.id}`)
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body pt-0">
            <b-tabs nav-class="nav-tabs-custom">
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">Liste des chauffeurs</a>
                </template>
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-3">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Afficher&nbsp;
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                        ></b-form-select
                        >&nbsp;entrées
                      </label>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <!-- <label class="d-inline-flex align-items-center">
                        Choisir
                    <multiselect
                    v-model="value1"
                    :options="options"
                    :multiple="true"
                    :searchable="false" class="col-md-12"
                    ></multiselect>
                    </label> -->
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-3">
                    <div
                      id="tickets-table_filter"
                      class="dataTables_filter text-md-right"
                    >
                      <label class="d-inline-flex align-items-center">
                        Rechercher:
                        <b-form-input
                          v-model="filter"
                          type="search"
                          class="form-control form-control-sm ml-2"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive">
                  <b-table
                    style="cursor: pointer"
                    class="table-centered"
                    :items="ordersData"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    @row-clicked="goToDetail"
                  >
                    <template v-slot:cell(action)="row">
                      <a
                        href="javascript:void(0);"
                        class="text-primary"
                        v-b-tooltip.hover
                        @click="editChauffeur(row.item)"
                        title="Editer"
                      >
                        <i class="mdi mdi-pencil font-size-18"></i>
                      </a>
                      <a
                        href="javascript:void(0);"
                        class="text-danger"
                        v-b-tooltip.hover
                        @click="desactiveChauffeur(row.item)"
                        title="Supprimer"
                      >
                        <i class="mdi mdi-trash-can font-size-18"></i>
                      </a>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-right"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active"
                    >Liste des chauffeurs désactivés</a
                  >
                </template>
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-3">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Afficher&nbsp;
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                        ></b-form-select
                        >&nbsp;entrées
                      </label>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6"></div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-3">
                    <div
                      id="tickets-table_filter"
                      class="dataTables_filter text-md-right"
                    >
                      <label class="d-inline-flex align-items-center">
                        Rechercher:
                        <b-form-input
                          v-model="filter"
                          type="search"
                          class="form-control form-control-sm ml-2"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive">
                  <b-table
                    style="cursor: pointer"
                    class="table-centered"
                    :items="ordersDataOff"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    @row-clicked="goToDetail"
                  >
                    <template v-slot:cell(action)="row">
                      <!--  <a
                        href="javascript:void(0);"
                        class="text-primary"
                        v-b-tooltip.hover
                        @click="editClick(row.item)"
                        title="Editer"
                      >
                        <i class="mdi mdi-pencil font-size-18"></i>
                      </a>
                      <a
                        href="javascript:void(0);"
                        class="text-danger"
                        v-b-tooltip.hover
                        @click="desactiverUser(row.item)"
                        title="Supprimer"
                      >
                        <i class="mdi mdi-trash-can font-size-18"></i>
                      </a> -->
                      <a
                        href="javascript:void(0);"
                        class="text-success"
                        v-b-tooltip.hover
                        @click="activeClient(row.item)"
                        title="Activer"
                      >
                        <i class="mdi mdi-check font-size-18"></i>
                      </a>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-right"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showDesactiveModal"
      title="Voulez-vous vraiment supprimer ce chauffeur ?"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showDesactiveModal = false"
          >Non</b-button
        >
        <b-button
          variant="success"
          class="ml-3 mr-4"
          @click="desactiveClientSubmit"
          >Oui</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showActiveModal"
      title="Voulez-vous vraiment activer ce chauffeur ?"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showActiveModal = false"
          >Non</b-button
        >
        <b-button
          variant="success"
          class="ml-3 mr-4"
          @click="activeClientSubmit"
          >Oui</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showEchec"
      title="L'envoie a échoué"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showEchec = false"
          >Reéssayez</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showSucessEdit"
      title="Modification effectuée!!!"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="success" @click="showSucessEdit = false"
          >OK</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showEchecEdit"
      title="Echec de la modification"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showEchecEdit = false"
          >Reéssayez</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="modal-center"
      centered
      size="xl"
      v-model="showEditModal"
      title="Editer un chauffeur"
      title-class="font-18"
      hide-footer
    >
      <form enctype="multipart/form-data" @submit.prevent="formSubmit">
        <div class="mt-2">
          <div class="row">
            <div class="col-md-6">
              <label for="">Nom</label>
              <input type="text" class="form-control" v-model="nom" />
            </div>
            <div class="col-md-6">
              <label for="">Prénom</label>
              <input type="text" class="form-control" v-model="prenom" />
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-md-6">
              <label for="">Ville de résidence</label>
              <input type="text" class="form-control" v-model="ville" />
            </div>
            <div class="col-md-6">
              <label for="">Contact</label>
              <MazPhoneNumberInput
                :class="{
                  'is-invalid': submitform && $v.contact.$error,
                }"
                v-model="contact"
                default-country-code="BJ"
                :translations="{
                  countrySelectorLabel: 'Code pays',
                  countrySelectorError: 'Choisir un pays',
                  phoneNumberLabel: 'Numéro de téléphone',
                  example: 'Exemple :',
                }"
              />
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-6">
            <label for="">Date de naissance</label>
            <input type="date" class="form-control" v-model="datenaissance" />
          </div>
          <div class="col-md-6">
            <label for="">Sexe</label>
            <multiselect
              v-model="sexeSelect"
              :options="sexe"
              :close-on-select="true"
            ></multiselect>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-6">
            <label for="">Email</label>
            <input type="email" class="form-control" v-model="email" />
          </div>
          <div class="col-md-6">
            <label for="">Numéro de pièce</label>
            <input type="text" class="form-control" v-model="numeropiece" />
          </div>
        </div>
        <!--<div class="row mt-4">
          <div class="col-md-3">
            <label for="">Photo de la pièce d'identité</label>
            <input type="file" class="form-control" />
          </div>
          <div class="col-md-3">
            <label for="">Photo du permis</label>
            <input type="file" class="form-control" />
          </div>
          <div class="col-md-6">
            <label for="">Photo du chauffeur</label>
            <input type="file" class="form-control" />
          </div>
        </div>-->
        <div class="float-right mt-4">
          <button class="btn btn-primary" @click="editChauffeurSubmit">
            Modifier
          </button>
        </div>
      </form>
    </b-modal>
  </Layout>
</template>
